import React from "react"
import { FaAlignRight } from "react-icons/fa"
import pageLinks from "../constants/links"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const Navbar = ({ toggleSidebar }) => {


  const activeStyle = {
    color: '#fc94a7'
  }

  return (
    <nav className="navbar">
      <div className="nav-center">
        <div className="nav-header">
        <Link to='/'>
        <StaticImage 
            src='../assets/images/bg-logo.png'
            alt='Bridal Gemms Logo'
            layout='fixed'
            width={250}
            className='bridal-logo'
            
          />
        </Link>
        
          <button type="button" className="toggle-btn" onClick={toggleSidebar}>
            <FaAlignRight />
          </button>
        </div>
        <div className="nav-links">
          {pageLinks.map(link => {
            return (
              <Link 
              key={link.id} 
              to={link.url}
              activeClassName='active-color'
              activeStyle={activeStyle}
              >
                {link.text} 
              </Link>
            )
          })}
        </div>
      </div>
    </nav>
  )
}

export default Navbar
