import React from "react"
import socialLinks from "../constants/social_links"
const Footer = () => {
  return (
    <footer className="footer">
      <div>
        <div className="footer-links social-links">
          {socialLinks.map(link => {
            return (
              <a href={link.url} key={link.id} className="social-link">
                {link.icon}
              </a>
            )
          })}
        </div>
        {/* <h5 style={{color: 'white'}}>
          Copyright&copy; {new Date().getFullYear()} 
           Bridal Gemms | Developed By Web Funnel
        </h5> */}

      <p style={{color: 'white'}}>
        &copy; {new Date().getFullYear()} Bridal Gemms | Developed By Web Funnel
      </p>
      </div>
    </footer>
  )
}

// Copyright © 2021 Travel Cebu | Designed By Web Funnel

export default Footer
